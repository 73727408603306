import styled from 'styled-components';
import React, { memo } from 'react';

const MicrosoftButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 13px 2px 2px;

  position: static;
  width: 168px;
  height: 41px;
  left: 184px;
  top: 0;

  background: #2f2f2f;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0 16px;

  border: none;

  :hover {
    background-color: #232323;
    cursor: pointer;
  }
`;

const MicrosoftLogoWrapper = styled.div`
  position: static;
  width: 34px;
  height: 37px;
  left: 2px;
  top: 2px;
`;

const MicrosoftLogo = () => (
  <svg width="34" height="37" viewBox="0 0 34 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="34" height="37" rx="2" fill="white" />
    <path d="M16.4737 8.5H7V17.9737H16.4737V8.5Z" fill="#F25022" />
    <path d="M16.4737 19.0264H7V28.5001H16.4737V19.0264Z" fill="#00A4EF" />
    <path d="M27.0001 8.5H17.5264V17.9737H27.0001V8.5Z" fill="#7FBA00" />
    <path d="M27.0001 19.0264H17.5264V28.5001H27.0001V19.0264Z" fill="#FFB900" />
  </svg>
);

const MicrosoftLogoMemoized = memo(MicrosoftLogo);

const MicrosoftText = styled.span`
  position: static;
  width: 52px;
  height: 20px;
  left: 52px;
  top: 10.5px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 16px;
`;

export function MicrosoftSignInButton({ onClick }: { onClick: () => any }) {
  return (
    <MicrosoftButton onClick={onClick}>
      <MicrosoftLogoWrapper>
        <MicrosoftLogoMemoized />
      </MicrosoftLogoWrapper>
      <MicrosoftText>Microsoft</MicrosoftText>
    </MicrosoftButton>
  );
}
