import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import AccountOnboardingHeader from '../../components/AccountOnboardingHeader';
import AccountOnboardingGreeting, { LOGIN } from '../../components/AccountOnboardingGreeting';
import LoginForm from '../../components/LoginForm';
import s from './Login.module.scss';
import withStyles from '../../core/isomorphic-style-loader/withStyles';
import { Col, Row, Alert } from 'antd';
import withViewport from '../../components/withViewport';
import { isWideFormat } from '../../core/util/device/screen';
import { PRE_CSS_LOADER_STYLE } from '../../constants';
import { safeGet } from '../../core/util/object';
import { emptyFunction } from '../../core/util/function';
import { trackIfLoadedInIframe } from '../../core/util/tracking';

class Login extends PureComponent {
  componentDidMount() {
    trackIfLoadedInIframe();
  }

  render = () => {
    const {
      viewport,
      isComingFromAccountOnboarding,
      isComingFromResetPassword,
      login,
      redirectUrl,
    } = this.props;

    return (
      <Row>
        <Row className="login-container" style={{ display: 'none' }}>
          <Col sm={{ offset: 2, span: 20 }}>
            <AccountOnboardingHeader hideLogin />
            <Row className="verify-create-content">
              {isWideFormat(viewport.width) && (
                <AccountOnboardingGreeting
                  case={LOGIN}
                  isComingFromAccountOnboarding={isComingFromAccountOnboarding}
                  isComingFromResetPassword={isComingFromResetPassword}
                />
              )}
              <Col xs={24} sm={12}>
                <Row>
                  <Col offset={3} span={18}>
                    <Row className="verify-create-form-header">Log In</Row>
                    {safeGet(login)('passwordResetRequested') && (
                      <Alert
                        message="Check your email"
                        description="Follow the link to reset your password."
                        type="info"
                        closable
                        showIcon
                        onClose={emptyFunction}
                      />
                    )}
                    <LoginForm redirectUrl={redirectUrl} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="login-loading" style={PRE_CSS_LOADER_STYLE}>
          <img src="/images/logo/Solv-Logo-DP-319x120.png" alt="Solv Login" width={200} />
          <div style={{ marginTop: '20px', color: '#b3b3b3' }}>Loading...</div>
        </Row>
      </Row>
    );
  };
}

Login.contextTypes = {
  setTitle: PropTypes.func,
};

Login.propTypes = {
  isComingFromAccountOnboarding: PropTypes.bool,
  isComingFromResetPassword: PropTypes.bool,
  persistentStateLoaded: PropTypes.bool,
  redirectUrl: PropTypes.string,
  viewport: PropTypes.object,
};

export default withViewport(withStyles(s)(Login));
