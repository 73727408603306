import { Input } from 'antd';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputProps } from 'antd/lib/input';
import { useFormError } from '../useFormError';
import { CommonFieldProps, useFixFieldProps } from '../common';
import { FieldWrapper, FieldWrapperProps } from './FieldWrapper';

export type SolvInputProps = InputProps & {
  formFieldComponent?: React.ComponentType;
} & CommonFieldProps &
  Partial<FieldWrapperProps>;

export function SolvInput(props: SolvInputProps) {
  const { helperText, ...restProps } = props;
  const { control } = useFormContext();
  const error = useFormError(restProps);
  const FormField = restProps.formFieldComponent ? restProps.formFieldComponent : FieldWrapper;
  const fieldProps = useFixFieldProps(props);

  return (
    <Controller
      {...fieldProps}
      render={({ field }) => (
        <FormField name={field.name} error={error} {...fieldProps}>
          <Input
            {...field}
            onChange={(event) => {
              if (fieldProps.type === 'number') {
                const value = event.target.value;
                if (value === '' || value == null) {
                  field.onChange(null);
                  return;
                }
                const numValue = parseFloat(value);
                if (!Number.isNaN(numValue)) {
                  field.onChange(numValue);
                  return;
                }
              }

              field.onChange(event);
            }}
            {...restProps}
          />
        </FormField>
      )}
      control={control}
      name={restProps.name}
    />
  );
}
