import styled, { css } from 'styled-components';
import React from 'react';
import { CommonFieldProps } from '../common';
import { Form } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

const Wrapper = styled.div<{ error?: boolean }>`
  ${(props) =>
    props.error &&
    css`
      border: 1px solid #f5222d !important;
      border-radius: 4px;
    `};
`;

export type FieldWrapperProps = Pick<CommonFieldProps, 'helperText' | 'required' | 'label'> & {
  name?: string;
  error?: string;
  children: any;
  showRequiredAsterisk?: boolean;
} & Omit<FormItemProps, 'id'>;

export function FieldWrapper(props: FieldWrapperProps) {
  const { required, error, helperText, children, label, showRequiredAsterisk, ...rest } = props;

  return (
    <Form.Item
      {...rest}
      label={
        label && required && showRequiredAsterisk ? (
          <RequiredFieldLabel label={label} required={required} />
        ) : (
          label
        )
      }
      validateStatus={error ? 'error' : ''}
      help={error ?? helperText}
      data-testid={undefined}
    >
      <InputWrapper error={!!error}>{children}</InputWrapper>
    </Form.Item>
  );
}

function RequiredFieldLabel({ label, required }: { label: any; required: boolean }) {
  if (!required) {
    return label;
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <span style={{ color: '#f5222d', paddingRight: '2px' }}>*</span>
      {label}
    </div>
  );
}

export function InputWrapper({ children, error }: { children: any; error?: boolean }) {
  return <Wrapper error={error}>{children}</Wrapper>;
}
